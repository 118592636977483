import React from 'react';
import Layout from '../../components/commons/Layout';
import styled from 'styled-components';
import theme from '../../constants/theme';
import DynamicZone from '../../components/commons/DynamicZone';
import PageTransition from '../../components/animations/PageTransition';
import CasiStudioSection from '../../components/homepage/CasiStudioSection';
import SectionHeroFullScreen from '../../components/sections/SectionHeroFullScreen';
import { graphql } from 'gatsby';

const CasiStudioDetailWrapper = styled.div`
  display: block;
  flex-direction: column;

  .detail-header {
    display: flex;
    flex-flow: column wrap;
    min-height: 100vh;

    h2 {
      font-size: 30px;
      text-transform: uppercase;
      line-height: 36px;
      margin-bottom: 30px;
    }

    h1 {
      margin-bottom: 20px;
    }

    .detail-image {
      flex: 1;
    }
  }

  ul.tags {
    margin-bottom: 30px;
    width: 100%;
    @media (min-width: 1280px) {
      white-space: normal !important;
    }

    li {
      display: inline-block;
      font-size: 21px;
      background-color: ${theme.colorYellow};
      padding: 5px;
      @media (min-width: 1280px) {
        margin-bottom: 5px;
      }
      text-transform: uppercase;
      margin-right: 25px;
    }
  }
`;

export default function ServiziDetail({
  location,
  pageContext: { node, casi, seoRules },
}) {
  const { titolo, sezioniDinamiche, immagineDettaglio, casiCorrelati } = node;

  let relatedCasi = [];

  if (casiCorrelati && casiCorrelati !== '') {
    relatedCasi = casiCorrelati
      .split(',')
      .map((id) => casi.find((c) => c.id === id))
      .filter(Boolean);
  }

  return (
    <Layout
      location={location}
      seo={seoRules && seoRules[0] && seoRules[0].seo}
      // headerWhite={true}
      disableBackgroundChange
    >
      <CasiStudioDetailWrapper>
        {/* <div className="detail-header container mx-auto">
          <h2>Caso Studio</h2>
          <h1 className="responsive-text">{titolo}</h1>
          {tagsArray && tagsArray.length > 0 && <ul className="tags">
            {tagsArray.map((tag, i) => (
              <li key={i}>{tag}</li>
            ))}
          </ul>}
          <div className="detail-image">
            {immagineDettaglio && <GatsbyImage image={getImage(immagineDettaglio.imageFile)} alt="" />}
          </div>
        </div> */}
        <SectionHeroFullScreen
          title={titolo}
          featuredImage={immagineDettaglio}
          titleResponsiveType="servizi"
          isDark
        />
        <DynamicZone sezioniDinamiche={sezioniDinamiche} />
        {relatedCasi.length > 0 && (
          <CasiStudioSection isHomePage casi={relatedCasi} />
        )}
      </CasiStudioDetailWrapper>
      <PageTransition />
    </Layout>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
